<template>
  <div>
    <!-- Media -->
    <b-media class="mb-2">
      <template #aside>
        <b-avatar
          ref="previewEl"
          :src="userData.avatar"
          :text="avatarText(userData.FullName)"
          :variant="`light-${resolveUserRoleVariant(userData.UserRoleName)}`"
          size="90px"
          rounded
        />
      </template>
      <h4 class="mb-1">
        {{ userData.FullName }}
      </h4>
    </b-media>

    <!-- User Info: Input Fields -->

    <validation-observer
          ref="editUserForm"
    >
    <!-- User Info: Input Fields -->
    <b-form
    @submit.prevent="editUser"
    >
      <b-row>
        <!-- Field: Kota -->
        <b-col
          cols="12"
          md="4"
        >
          <validation-provider
            #default="validationContext"
            name="Full Name"
            rules="required"
          >
          <b-form-group
            label="FullName"
            label-for="FullName"
          >
            <b-form-input
              id="FullName"
              v-model="FullName"
            />
          </b-form-group>
          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
          </validation-provider>
        </b-col>

        <!-- Field: Email -->
        <b-col
          cols="12"
          md="4"
        >
          <validation-provider
            #default="validationContext"
            name="Email"
            rules="required"
          >
          <b-form-group
            label="Email"
            label-for="Email"
          >
            <b-form-input
              id="Email"
              v-model="Email"
              type="text"
            />
          </b-form-group>
          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
          </validation-provider>
        </b-col>

        <!-- Field: Phone -->
        <b-col
          cols="12"
          md="4"
        >
          <validation-provider
            #default="validationContext"
            name="PhoneNumber"
            rules="required"
          >
          <b-form-group
            label="Phone"
            label-for="PhoneNumber"
          >
            <b-form-input
              id="PhoneNumber"
              v-model="PhoneNumber"
            />
          </b-form-group>
          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
          </validation-provider>
        </b-col>
        <!-- Field: Branch -->
        <b-col
          cols="12"
          md="4"
          v-if="isShownBranch==true"
        >
            <b-form-group
              label="Branch"
              label-for="BranchId"
            >
              <v-select
                v-model="BranchID"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="branches"
                :reduce="val => val.value"
                :clearable="false"
                input-id="BranchId"
              />
            </b-form-group>
        </b-col>
        <!-- Field: Role -->
        <b-col
          cols="12"
          md="4"
        >
            <b-form-group
              label="Role"
              label-for="RoleID"
            >
              <v-select
                v-model="RoleID"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="roles"
                :reduce="val => val.value"
                :clearable="false"
                input-id="RoleID"
              />
            </b-form-group>
        </b-col>
        <!-- Field: Departements -->
        <b-col
          cols="12"
          md="4"
          v-if="isDepartementShown==true"
        >
            <b-form-group
              label="Departement"
              label-for="DepartementID"
            >
              <v-select
                v-model="DepartementID"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="departements"
                :reduce="val => val.value"
                :clearable="false"
                input-id="DepartementID"
              />
            </b-form-group>
        </b-col>
        <!-- User Region -->
        <b-col
          cols="12"
          md="4"
          v-if="isShownRegion==true"
        >
            <b-form-group
              label="Regions"
              label-for="RegionID"
            >
              <v-select
                v-model="RegionIDs"
                name="RegionIDs"
                :options="regions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="RegionIDs"
                multiple
              />
            </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <validation-provider
            #default="validationContext"
            name="Occupation"
            rules="required"
          >
          <b-form-group
            label="Occupation"
            label-for="Occupation"
          >
            <b-form-input
              id="Occupation"
              v-model="Occupation"
            />
          </b-form-group>
          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
          </validation-provider>
        </b-col>
      </b-row>
    <!-- Action Buttons -->

    <b-button
              type="button"
              variant="primary"
              class="mr-1"
              @click="previous()"
            >
              <feather-icon
                icon="ArrowLeftIcon"
                size="16"
                class="align-middle"
              />
              Back
            </b-button>
    <b-button
      variant="primary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      type="submit"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
    >
      Save Changes
    </b-button>
    </b-form>
    </validation-observer>
    <!-- <b-button
      variant="outline-secondary"
      type="reset"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
    >
      Reset
    </b-button> -->
  </div>
</template>

<script>
import {
  BButton, BMedia, BRow, BCol, BAvatar, BFormGroup, BFormInput, BForm,
} from 'bootstrap-vue'
import axios from 'axios'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import moment from 'moment'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useUsersList from '../users-list/useUsersList'

const genders = [
          { label: 'Pria', value: 'L' },
          { label: 'Wanita', value: 'P' },
        ]
const regions = []
const isShownRegion = false
const isShownBranch = true
export default {
  components: {
    BButton,
    BMedia,
    vSelect,
    BRow,
    BCol,
    BAvatar,
    BFormGroup,
    BFormInput,
    BForm,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { resolveUserRoleVariant } = useUsersList()

    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      props.userData.avatar = base64
    })

    return {
      resolveUserRoleVariant,
      avatarText,
      //  ? Demo - Update Image on click of update button
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
  data() {
    return {
      regions,
      isShownBranch,
      isShownRegion,
      roles: [],
      branches: [],
      departements: [],
      genders,
      isDepartementShown: false,
      UserID: this.userData.user_id,
      Email: this.userData.user_username,
      FullName: this.userData.user_fullname,
      PhoneNumber: this.userData.user_phone,
      RoleID: this.userData.user_role_id,
      BranchID: this.userData.user_branch_id,
      DepartementID: this.userData.user_departement_id,
      Occupation: this.userData.user_occupation,
      RegionIDs: [],
      isValidEmail: true,
      required,
    }
  },
  mounted() {
      this.getRoles()
      this.getBranches()
      this.getDepartements()
      if (this.RoleID === 18 || this.RoleID === 15) {
        this.isDepartementShown = true
      }
      this.getRegions()
      if (this.RoleID === 5) {
        this.isShownRegion = true
        this.isShownBranch = false
      }
  },
  methods: {
    getRegions() {
          const userToken = this.$cookies.get('userToken')
          const headers = {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userToken}`,
          }
          axios
              .get(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_REGION}`, { headers })
              .then(response => {
                const regionsJoin = []
                response.data.data.map(elem => {
                  regionsJoin.push({ label: elem.region_name, value: elem.region_id, slug: elem.region_slug })
                })
                this.regions = regionsJoin
              })
              .catch(err => {
                  console.log(err)
              })
      },
      getRoles() {
        const userToken = this.$cookies.get('userToken')
const headers = {
  'Content-Type': 'application/json',
  Authorization: `Bearer ${userToken}`,
}
          axios
              .get(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_ROLES}`, { headers })
              .then(response => {
                const rolesJoin = response.data.data.map(elem => (
                  { label: elem.role_name, value: elem.role_id }
                ))
                this.roles = rolesJoin
              })
              .catch(err => {
                  console.log(err)
              })
      },
      getBranches() {
        const userToken = this.$cookies.get('userToken')
const headers = {
  'Content-Type': 'application/json',
  Authorization: `Bearer ${userToken}`,
}
          axios
              .get(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_BRANCHES}`, { headers })
              .then(response => {
                const branchesJoin = response.data.data.map(elem => (
                { label: `${elem.branch_city} (${elem.branch_code})`, value: elem.branch_id, kodeunit: elem.branch_code }
                ))
                this.branches = branchesJoin.sort((a, b) => parseInt(a.kodeunit) > parseInt(b.kodeunit) ? 1 : -1)
              })
              .catch(err => {
                  console.log(err)
              })
      },
      getDepartements() {
        const userToken = this.$cookies.get('userToken')
const headers = {
  'Content-Type': 'application/json',
  Authorization: `Bearer ${userToken}`,
}
          axios
              .get(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_DEPARTEMENTS}`, { headers })
              .then(response => {
                const rolesJoin = []
                response.data.data.map(elem => {
                    rolesJoin.push({ label: elem.departement_name, value: elem.departement_id })
                })
                this.departements = rolesJoin
              })
              .catch(err => {
                  console.log(err)
              })
      },
    previous() {
          this.$router.push({ name: 'apps-users-list' })
    },
    editUser() {
      const userToken = this.$cookies.get('userToken')
const headers = {
  'Content-Type': 'application/json',
  Authorization: `Bearer ${userToken}`,
}
      this.$refs.editUserForm.validate().then(success => {
        const payload = {
          user_id: this.UserID,
            user_username: this.Email,
            user_fullname: this.FullName,
            user_phone: this.PhoneNumber,
            user_role_id: this.RoleID,
            user_occupation: this.Occupation,
            user_branch_id: this.BranchID,
            user_departement_id: this.DepartementID,
            time: moment(),
          }
        if (success) {
          axios
          .post(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_EDIT_USER}`, payload, { headers })
          .then(response => {
              // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
              if (response.data.success === true) {
                this.$root.$emit('refreshTable', 'refreshTable')
                // this.$parent.refBranchListTable.refresh()
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Congratulation',
                    icon: 'CoffeeIcon',
                    variant: 'success',
                    text: 'Your data has been saved!',
                  },
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Notification',
                    icon: 'BellIcon',
                    variant: 'danger',
                    text: 'Something went wrong',
                  },
                })
              }
              }).catch(error => {
                console.log(error)
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Notification',
                    icon: 'BellIcon',
                    variant: 'danger',
                    text: 'Something went wrong',
                  },
                })
              })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
